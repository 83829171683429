@import url('https://fonts.googleapis.com/css2?family=Mogra&family=Shippori+Antique&family=Ubuntu:wght@500&display=swap');

body{
        margin: 0!important;
        padding: 0 !important;
font-family: 'Ubuntu', sans-serif;
}
.navbar-brand img{
     
        margin-right: 10px;
        height: 40px;
}




@media only screen and (max-width: 1310px) {
        .navdiv{
        width: 100vw !important;
        }
}


.new-nav{
z-index: 999;
position: relative; 
width: calc(100% - 200px);
padding: 0 8px;
left: 200px;
border: 1px rgb(0, 0, 0) solid;
border-right: none;
border-bottom: none;
}
.new-nav2{
        /* z-index: 999999; */
        border-left: 1px rgb(0, 0, 0) solid;
        position: relative; 
        width: calc(100% - 200px);
        padding:  8px;
        left: 200px;
        top: 0;
        }
        .new-nav2 .navbar-nav .nav-item a i{
              color: rgb(0, 0, 0);
              font-size: 20px;
              margin: 0 10px;
        }

/* .b-color{background-color: rgb(255, 51, 0) !important;} */

        @media only screen and (max-width: 995px) {
                .new-nav2{
                        border: none;
                }    
                .new-nav {
                        border: none;
                        position: absolute;
                        top: 19px;
                        left: 1vw;
                        width: calc(100% - 1vw);
                        z-index: 1111 !important;
                } 
                .new-nav .navbar-nav{
                        display: flex !important;
                        flex-direction: row;
                        justify-content: flex-end;
                }
                .new-nav .navbar-nav li{
                        text-align: right !important;
                }
        }

        .new-nav .navbar-nav li .navlink{
                color: #000 !important;
                text-shadow: 0 2px 3px  ghostwhite;
        }
        @media only screen and (max-width: 767px) {
                .new-nav .navbar-nav{
                        position: absolute;
                        width: calc(100% - 200px) !important;
                        right: 50px;}
                      
                        .new-nav .navbar-nav li .navlink{
                                font-size: 14px !important;
                                margin: 0;
                        }

                .new-nav2 .navbar-nav{
                        position: absolute;
                        right: 10px;
                        top: 15px ;}
                       

                        .new-nav2 .navbar-nav .nav-item a i{
                                color: rgb(0, 0, 0);
                                font-size: 18px;
                                margin: 0 5px;
                          }
        }

        @media only screen and (max-width: 646px) {
                .new-nav .navbar-nav .nav-item {
                        padding:  5px 8px !important;
                        margin-top: 5px;
                       
                }     
        
        }

           

.tglbtn{
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 99;
        background-color: #fcfcfc5b;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 30px;
        cursor: pointer;
        padding: 4px 10px;
        display: none;
}
.tglbtn:hover{ background-color: #c4dee65b;
        border: 1px solid #c4dee6;
        color: #000000;
}

        .bar1, .bar2, .bar3 {
                width: 25px;
                height: 3px;
                background-color: #333;
                margin: 6px 0;
                transition: 0.4s;
              }
              @media only screen and (max-width: 600px) {
                .tglbtn{
                        display: block !important;
                }
                
                .navdiv{
                        display: none !important;
                        }
                .navclps{
                        display: flex !important;
                        padding: 0 !important;
                        margin: 0 !important;
                        width: 100% !important;
position: fixed ;
top: 0 !important;
left: 0 !important;
height: auto;
                 }
                             
                 .navclps .new-nav {
                        background: linear-gradient(45deg,  rgba(230, 230, 243, 0.950),rgba(232, 243, 232, 0.950));
                         position:  absolute !important;
                         display: inline-block !important; 
                         width: 100% !important;   
                         left: 0 !important;
                        top: 0;}
                .navclps .new-nav .navbar-nav{
position:  relative !important;
width: 100% !important; 
padding: 20px !important;
left: 0 !important;
display: block !important;
                }     
                
                .navclps .new-nav .nav-item{
                        margin-left: 20px;
                        text-align: left !important;
                }
                .navclps.new-nav .navbar-nav .nav-item{
                        margin-left: 20px;
                        text-align: left !important;
                   }     
                   .navclps .new-nav .navbar-nav li .navlink{
                        font-size: 16px !important;
                        color: #000 !important;
                }
        

                .navclps .new-nav2 .navbar-nav{
                        z-index: 333;
                        position: absolute;
                        z-index: 999999;
                        right: 30px;
                        top: 100px;}
                       

                        .navclps .new-nav2 .navbar-nav .nav-item a i{
                                color: rgb(0, 0, 0);
                                font-size: 18px;
                                z-index: 999999;
                                margin: 20 5px !important;
                          }
        
        }
                
.navclps{
       display: none;
}

.new-nav .navbar-nav .nav-item{
        padding: 8px 15px;
        cursor: pointer;
        text-transform: uppercase;
        text-align: right;
}
.new-nav .navbar-nav .nav-item:hover{
        color: rgb(0, 0, 0) !important;
        background-color: rgba(255, 255, 255, 0.918) !important;
        z-index: 99999999;
}
.benner
{top: 0px;
        position: relative;
        width: 100%;
        height: auto;
        -o-object-fit: cover;
           object-fit: cover;
}
.bennerv2 video {
        max-height: 700px;
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
    }

.benner img{
        max-height: 300px;
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
}

.cam-box{
        border-radius: 6px;
        box-shadow: 0 0 5px 3px rgba(82, 82, 82, 0.356) !important;
    
}
.sliderfadeimg{
        width: 100%;
        border-radius: 5px;
        height: 280px;
        -o-object-fit: cover;
           object-fit: cover;
}
@media only screen and (max-width: 454px) {
        .sliderfadeimg{
        height: 250px;}}
@media only screen and (max-width: 767px) {
        .bnrtext{display: none;}
.null-tbox{
        display: none;
}
}
.bnrtext{
        padding: 10px 110px 10px 10px;
        -webkit-clip-path: polygon(0 0, 70% 0, 70% 35%, 80% 35%, 80% 15%, 100% 50%, 100% 50%, 80% 85%, 80% 65%, 70% 65%, 70% 100%, 0 100%);
                clip-path: polygon(0 0, 70% 0, 70% 35%, 80% 35%, 80% 15%, 100% 50%, 100% 50%, 80% 85%, 80% 65%, 70% 65%, 70% 100%, 0 100%);
        background-color: black;
        text-align: center;
        height: 120px;
        width: 350px;
        position: absolute;
        color: wheat;
        top: 200px;
        right: 40%;
}


.nav-item{
        color: rgb(65, 92, 170) !important;
}
.navbar-brand{
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        position: relative;
        color: rgb(65, 92, 170) !important;
}

@media only screen and (max-width: 646px) {
        .explainbox{
                margin-top: -20px !important; 
        }}









.boxitem{
        background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
margin-left: 20px;
padding: 30px;
border-radius: 6px;
box-shadow: 0 1px 6px rgba(148, 148, 148, 0.897);
}
.kn-btn{box-shadow: 0 2px 5px 2px rgba(95, 95, 95, 0.637); }
.kn-btn:hover{
        box-shadow: 0 0 10px 4px rgba(4, 26, 62, 0.61);
}
.boxitem h2{
        color:  rgb(75, 104, 122);
margin-bottom: 20px;
font-weight: bolder;
}
.boxitem p{
        color: rgb(45, 107, 109) !important;
}
.boxitem h3{
        color:  rgb(65, 95, 129);
margin-bottom: 20px;
font-weight: bolder;
}
.boxitem ul li{
        color: rgb(41, 56, 102) !important;

}

@media only screen and (min-width: 768px) {
        .formbox{
        max-height: 400px;
        }
}
.formbox
{
        /* background: linear-gradient(90deg, rgba(210, 210, 236, 0.8), rgba(212, 240, 243, 0.8)), url("./assets/images/formimg.jpg") no-repeat;  */
        background: linear-gradient(90deg, rgba(210, 210, 236, 0.8), rgba(212, 240, 243, 0.8)), url("#") no-repeat; 
        background-size: cover;
  padding: 30px;
 border-radius: 5px;
 z-index: 30 !important;
 box-shadow: 0 1px 6px rgba(148, 148, 148, 0.897);  
}

.formbox::after{
        z-index: 30 !important;
}
.formbox::before{
        z-index: 30 !important;
}

.formbox input {
        box-shadow: 0 2px 5px 2px rgba(95, 95, 95, 0.267);    
        margin-bottom: 15px;
        border: none;
        color: #444444;
        background:linear-gradient(90deg, rgba(196, 196, 230, 0.8), rgba(195, 234, 238, 0.8));
        box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25),
        inset 2px 2px 5px rgba(255, 255, 255, 0.35),
        inset -3 -3 5px rgba(0, 0, 0, 0.25) !important;

}
  ::-webkit-input-placeholder {
        color: #000000 !important;
}
  ::-moz-placeholder {
        color: #000000 !important;
}
  :-ms-input-placeholder {
        color: #000000 !important;
}
  ::-ms-input-placeholder {
        color: #000000 !important;
}
  ::placeholder {
        color: #000000 !important;
}

.formbox input:hover {
        background: linear-gradient(90deg, rgb(217, 217, 238), rgb(230, 230, 240), white, rgb(238, 243, 243), rgb(224, 239, 240));
        box-shadow: 0 0 5px 3px rgba(155, 155, 155, 0.384);   
        
}

.formbox input:active {
       background-color: #ececec;   
       box-shadow: 0 0 10px 10px rgb(255, 255, 255); 
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formbox select{
        margin-bottom: 15px;
}

.formbox h3{        
 
        margin-bottom: 20px;
        font-weight: bolder;
        line-height: 1;
        color: transparent;
        background-image: linear-gradient(to left, red, yellow, green, blue,red, yellow, green, blue);
        -webkit-background-clip: text;
        -webkit-animation: animate 10s linear infinite;
                animation: animate 10s linear infinite;
        -webkit-backdrop-size: 500%;
        background-size: 500%;
}
@-webkit-keyframes animate{
        0%{
                background-position: 0 100%;
        }
        50%{
                background-position:  100% 0%;
        }
        100%{
                background-position: 0% 100%;
        }
}
@keyframes animate{
        0%{
                background-position: 0 100%;
        }
        50%{
                background-position:  100% 0%;
        }
        100%{
                background-position: 0% 100%;
        }
}


.formbox button{
        background: linear-gradient(45deg, #005cbe,#42a4ff);
        box-shadow: 0 2px 5px 2px rgba(95, 95, 95, 0.788); 
  font-size: 16px;
  align-self: center;
  text-align: center;
  margin-bottom: 15px;
  padding: 6px 13px;
  border-radius: 4px;
  border: none;
  color: white;
 
  display: block;
}

.formbox button:active{
        outline: 4px solid rgba(12, 125, 201, 0.432);
        box-shadow: 0 0 10px 10px rgb(255, 255, 255) !important; 
}

.gallery-box{
        padding: 0;
        border-radius: 10px;
        overflow: hidden;      
        box-shadow: 0 1px 6px rgba(70, 70, 70, 0.993);  
        margin-bottom: 80px;
}
.sc-papXJ{
        border: none !important;
}

.formbox button:hover{
  box-shadow: 0 0 10px 4px rgba(4, 26, 62, 0.61);
  background: linear-gradient(45deg, #42a4ff, #005cbe);}

.formbox p{  
              color: rgb(65, 92, 170);
        font-size: 12px;
}
.nav-item a{
        color: rgb(65, 92, 170);
        margin-left: 10px;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        font-family: Arial, Helvetica, sans-serif;

}
.navitem p{
        
        color: rgb(65, 92, 170);;
        margin: 10px;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        font-family: Arial, Helvetica, sans-serif; 
}
footer{
        background-color: black ;
        padding: 40px;
}
footer h4{
        font-size: 25px;
        color: white;
        font-weight: bolder;
}
footer p{

        font-size: 16px;
        color: white;
}
footer a{
        text-align: right !important;
        }

 footer a img{
         height: 40px;
 }       

 .ponsercontainer{
         padding: 20px;
         margin-top: 20px;
         margin-bottom: 20px;
 }
 .ponsercontainer a img{

        height: 100px;
        width: auto;
        margin-left: 50px;
 }

 .containlargbox{
         padding: 20px;
 }
 .containlargbox h1{
         font-size: 25px;
         color:  rgb(43, 67, 82);
         font-weight: bolder;
         margin-bottom: 15px;
         
}
.containlargbox p{
        font-size: 16px;
        
}

 .containlargbox2{
        padding: 10px;
        margin-top: 20px;
        background-color: rgb(75, 104, 122); 
}

.vbox2{
        position: -webkit-sticky !important;
        position: sticky !important;
        background-color:rgb(34, 78, 61);
}
.containlargbox2 h6{
        color: rgb(255, 255, 255);
        font-size: 18;
        font-weight: bolder;
}
@media only screen and (max-width: 767px) {
        
.mdcontainbox{border: 1px solid black;
}
.formbox
{
        margin-top: 30px;}
.boxitem{
margin-left: 0px;}
}

.mdcontainbox{padding: 10px 0 0 0px;
        border-left: 1px solid black;
        border-top: 1px solid black;
}

.border-b{
        border-bottom: 1px solid black !important;
}
.border-r{
        border-right: 1px solid black;
}

.mdcontainbox{
       font-size: 16px;
       text-align: center;
}

.Testimoni{
        border-radius: 5px;
        margin: 0;
        padding:10px;
        background-color: rgb(250, 253, 255);
}

.iframe{
        margin: 10px 0;
        padding: 0 5px;
}

.iframe iframe{height: auto;
        width: 100%;
}

.foterhead{
        font-size: 28px;
}

.fotertext{
        color: rgb(75, 104, 122);
}

.containboxv2{
        background-color:lightgoldenrodyellow;
}


.centercontain{
        background-color:aliceblue;
        margin: 20px 0;
}
.centercontain2{
        background-color:rgb(243, 214, 220);
        margin: 20px 0;
}
.centercontain2 h1{
        font-size: 30px;
        text-align: center;
        color: rgb(117, 67, 50);
}
.centercontain2 p{
        text-align: center;
        color: crimson;
}

.centercontain h1{
        font-size: 30px;
        text-align: center;
}

.boxitemv2{
        background-color: azure;
}
.centercontain3{
        background-color:rgba(240, 255, 240, 0.911);
        margin: 20px 0;
}
.centercontain3 h1{
        font-size: 30px;
        text-align: center;
        color:darkgreen;
}
.centercontain3 p{
        text-align: center;
        color:teal;
}

.modal-content
{
        padding: 50px;
}
.modal-content p
{font-size: 20px;
}

.discailmer {

        text-align: center;
text-shadow: 0 0px 80px rgba(4, 26, 62, 0.993);
}

.card iframe{
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        min-height: 220px;
        }         
.bennerv2 img {
    max-height: 500px;
}
        
        .buttomstylebox{
                background: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 0, 0, 0), rgba(255, 255, 255, 0.137), rgba(255, 255, 255, 0.342), rgba(255, 255, 255, 0.644),rgba(255, 255, 255, 0.945), rgb(255, 255, 255));
                height: 300px;
                width: 100%;
                position: absolute;
                bottom: 0px;
                z-index: 3;
        }

        .b-color{
                margin-top: 40px;
                position: absolute;
                background-color: transparent !important;
        }
        @media only screen and (max-width: 767px) {

                .search-hover {
left: 175px !important;
}
.serchicon{
        right: -149px !important;
        top: 31px !important;}
 
        }

        @media only screen and (max-width: 484px) {

                .search-hover:hover {
                        width: 200px !important;
                        padding-left: 30px !important;}
                        .serchicon:hover ~ .search-hover{
                                width: 200px !important;
                                padding-left: 30px !important;}
        }
        @media only screen and (max-width: 380px) {

                .search-hover:hover {
                        width: 150px !important;
                        padding-left: 20px !important;}
                        .serchicon:hover ~ .search-hover{
                                width: 150px !important;
                                padding-left: 20px !important;}
        }
        .search-hover {
                position: relative;
                left: 150px;
                top: 8px;
                border: none;
                background-color: rgba(255, 255, 255, 0.274);
                outline: none;
                background-size: 22px;
                background-position: 13px;
                border-radius: 10px;
                width: 50px;
                height: 50px;
                padding: 25px;
                transition: all 0.5s;
              }
              .search-hover:hover {
                width: 300px;
                padding-left: 50px;
                background-color: rgba(240, 248, 255, 0.795);
              }
              .search-hover:active {
                width: 300px;
                padding-left: 50px;
                background-color: rgba(240, 248, 255, 0.795);
              }
              .serchicon{
                      position: absolute;
                      font-size: 30px;
                      right: -125px;
                      top: 23px;
                      z-index: 33;
              }

              .serchicon:hover ~ .search-hover{
                width: 300px;
                padding-left: 50px;
        }

        .search-hover:hover ~ .serchicon{
                display: none !important;
        }
        @media only screen and (min-width: 767px) {
        
                .University-slider2{
                        margin-left: 20px;
                }}
                .University-slider{
                        margin-top: 20px;
                }
                .customcard{
                        height: 300px;
                        border-radius: 0;
                        border: none;
                    
                        background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
                        padding: 20px;
                }


                .card-img-box{
                        height: 300px;
                        border: none;
                }

                .card-img-box img{
                        height: 100%;
                        max-width: 100%;
                        object-fit: cover;
                }
                .m-l-s{
                        margin: 0;
                }

                .customcard button
                {background: linear-gradient(45deg, #005cbe,#42a4ff);
                        position: absolute;
                        bottom: 20px;
                        border: none;
                }


                .customcard button:hover
                {background: linear-gradient(45deg, #42a4ff, #005cbe);
                }
             

                .customcard p{
                        font-size: 12px;
                }
.causol-row{
        margin: 5px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 1px 6px rgba(148, 148, 148, 0.897);
}
.margi-s{
        margin: 0!important;
        padding: 0 !important;
}
.margi-s2{
        margin: 0!important;
        padding: 0 !important;
}       .container.gallery-container {
                        background-color: #fff;
                        color: #35373a;
                        min-height: 100vh;
                        padding: 30px 50px;
                    }
                    
                    .gallery-container h1 {
                        text-align: center;
                        margin-top: 50px;
                        font-family: 'Droid Sans', sans-serif;
                        font-weight: bold;  margin-bottom: 20px;
                        font-weight: bolder;
                        line-height: 1;
                        color: transparent;
                        background-image: linear-gradient(to left,  green, blue, green, blue);
                        -webkit-background-clip: text;
                        -webkit-animation: animate2 5s linear infinite;
                                animation: animate2 5s linear infinite;
                        -webkit-backdrop-size: 500%;
                        background-size: 500%;
                        text-shadow: 0 2px 2px rgba(90, 139, 199, 0.76);
                }
                @-webkit-keyframes animate2{
                        0%{
                                background-position: 0 100%;
                        }
                        50%{
                                background-position:  100% 0%;
                        }
                        100%{
                                background-position: 0% 100%;
                        }
                }
                @keyframes animate2{
                        0%{
                                background-position: 0 100%;
                        }
                        50%{
                                background-position:  100% 0%;
                        }
                        100%{
                                background-position: 0% 100%;
                        }
                }  
                    .gallery-container p.page-description {
                        text-align: center;
                        margin: 25px auto;
                        font-size: 18px;
                        color: #999;
                    }
                    
                    .tz-gallery {
                        padding: 40px;
                        position: relative;
                    }
                    .tz-gallery .row > div {
                        padding: 2px;
                    }
                    
                    .tz-gallery .lightbox img {
                        width: 100%;
                        height: 100%;
                        -o-object-fit: cover;
                           object-fit: cover;
                        border-radius: 0;
                        position: relative;
                        height: 300px;
                    }
                    .tz-gallery .lightbox:before {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -13px;
                        margin-left: -13px;
                        opacity: 0;
                        color: #fff;
                        font-size: 26px;
                        font-family: 'Glyphicons Halflings';
                        content: '\e003';
                        pointer-events: none;
                        z-index: 9000;
                        transition: 0.4s;
                    }
                    
                    
                    .tz-gallery .lightbox:after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        content: '';
                        transition: 0.4s;
                    }
                    
                    .tz-gallery .lightbox:hover:after,
                    .tz-gallery .lightbox:hover:before {
                        opacity: 1;
                    }
                    
                    .baguetteBox-button {
                        background-color: transparent !important;
                    }
                    
                    @media(max-width: 768px) {
                        body {
                            padding: 0;
                        }
                    }

                    .customcard .card-body h5{
                            display: flex;
                        font-size: 28px;
                        flex-direction: row;
                        margin-top: -8px;
                         color: rgb(42, 84, 124);
                         margin-bottom: 15px;
                         text-shadow: 0 2px 4px rgba(68, 68, 68, 0.808);
                }
                    .rank{
                            background-color: red;
                            height: 41px;
                            min-width: 41px !important;
                            width: 41px;
                            padding-bottom: 7px;
                            font-size: 25px;
                            border-radius: 50%;
                            margin-left: 20px;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: rgb(255, 255, 255);
                            opacity: 2;
                            border: yellow 1px solid;
                            outline: gold 2px solid;
                            font-weight: bolder;
                            -moz-border-radius: 50%;
                    }
                    .rank span{
                            opacity: 1;
                            padding-top: 6px;
                            background-image: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 0, 0, 0),rgba(255, 0, 0, 0), rgba(255, 0, 0, 0),rgb(255, 0, 0),rgb(255, 0, 0), rgb(255, 0, 0));
                            position: absolute;
                            z-index: 1;
                            bottom: -20px;
                            color: gold;
                            font-size: 10px;
                            width: 100%;
                            height: 40px;
                            text-align: center;
                            -webkit-clip-path: polygon(0 9%, 11% 44%, 36% 51%, 0 100%, 36% 100%, 50% 59%, 66% 100%, 100% 100%, 66% 51%, 87% 44%, 100% 10%, 46% 13%);
                                    clip-path: polygon(0 9%, 11% 44%, 36% 51%, 0 100%, 36% 100%, 50% 59%, 66% 100%, 100% 100%, 66% 51%, 87% 44%, 100% 10%, 46% 13%);

                    }
                    .lunch-popup{
                            background-color: rgba(0, 0, 0, 0.473);
                            position: fixed;
                            width: 100%;
                            height: 100%;
                            z-index: 100;
                            top: 0;

                    }
                    @media(max-width: 434px) {
                                .closebox{height: 30px !important;
                                        width: 30px !important;
                                        right:  30px !important;
                                        top: 30px !important;}
                                        .closebox:hover{
                                                height: 28px !important;
                                        width: 28px !important;
                                        right:  31px !important;
                                        top: 31px !important;
                                        }
                    }
                    .closebox{
                        -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
                                clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
background-color: black;
border: none ;
height: 40px;
width: 40px;
z-index: 6666;
position: absolute;
right:  40px;
top: 40px;
cursor: pointer; }
.closebox:hover{
        background-color: rgb(209, 0, 0);
        height: 38px;
width: 38px;
right:  41px;
top: 41px;
}

.btn-dark:hover{
        box-shadow: 0 0 10px 4px rgba(4, 26, 62, 0.61);
}

.full-image figure img{
width: 80% !important;
-o-object-fit: cover !important;
   object-fit: cover !important;
height: 80% !important;

}
.pop-logo{
        margin-bottom: 38px !important;
}
.pop-logo img{
        height: 60px;
        margin-right: 20px;
        margin-bottom: -44px !important;
}              
   
          @media(max-width: 575px) {
.t-mar{
        margin: 0 5px;}
}
@media(min-width: 767px) {
.containboxv2{ padding-bottom: 70px;}}
.instructions {
        text-align:center;
        font-size:20px;
        margin: 15vh;
      }  
      .backgroundOverlay {
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              height: 100%;
              width: 100%;
              margin: 0;
              padding: 0;
              background: #000000;
              opacity: .85;
              filter: alpha(opacity=85);
              -moz-opacity: .85;
              z-index: 101;
              display: none;
      }
    
      .delayedPopupWindow {
              display: none;
              position: fixed;
              width: 100%;
              height: 100%;
              top: 0;
              margin: 0;
              background: #000000b7;             
              display: none;
              z-index: 102;
              padding: 10px;
      }
     
      #btnCloses:hover {
              background-color: #c90c12 !important;
      }
  
      #delayedPopup > div.formDescription {
              float: left;
              display: block;
              width: 44%;
              padding: 1% 3%;
              font-size: 18px;
              color: #666;
              clear: left;
      }
      #delayedPopup > div.formDescription h2 {
              color: #444444;
              font-size: 36px;
              line-height: 40px;
      }
      #delayedPopup #mc_embed_signup {
            padding: 10px;
            position: fixed;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 !important;
      }
      #delayedPopup #mc-embedded-subscribe-form input:hover {
              border:solid 2px #40c348;
              box-shadow: 0 1px 3px #AAAAAA;
      }
      #delayedPopup #mc-embedded-subscribe-form input:focus {
              border:solid 2px #40c348;
              box-shadow: none;
      }
      #delayedPopup #mc-embedded-subscribe {
              width: 100%!important;
              height: 40px!important;
              margin: 10px auto 0 auto;
              background: #5D9E62;
              border: none;
              color: #fff;
      }
      #delayedPopup #mc-embedded-subscribe:hover {
              background: #40c348;
              color: #fff;
              box-shadow:none!important;
              cursor: pointer;
      }
@media(max-width: 858px) {
        .pop-card{
                margin-right: 20px!important;
        }
}
@media (min-width: 990px) and (max-width: 1200px) {
        .section-inner {
                align-items: center;
                position: relative;
                min-width: 100%;
                margin: 0;
                padding: 0;
        }        }
      .section-inner {
             margin-top: 30px;
            
      }
      #feedback {
              cursor: pointer;
	height: 0px;
	width: 85px;
	position: fixed;
	right: 0;
	top: 50%;
	z-index: 10;
	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
#feedback a {
        cursor: pointer;
	display: block;
	background:#000;
	height: 52px;
	padding-top: 10px;
	width: 155px;
	text-align: center;
	color: rgb(255, 255, 255);
	font-family: Arial, sans-serif;
	font-size: 17px;
	font-weight: bold;
	text-decoration: none;

        -webkit-animation-duration: 2.5s;

                animation-duration: 2.5s;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-name: bounce;
                animation-name: bounce;
 
}
#feedback a:hover {
        box-shadow: 0 0 5px 3px rgba(100, 100, 100, 0.384);  
        font-size: 19px; 
        background-color: white;
        color: rgb(0, 24, 0);
        -webkit-animation-name: wobble;
                animation-name: wobble;

}
         
        @-webkit-keyframes bounce {
        0%, 20%, 50%, 80%, 100% {transform: translateY(0);
        }
        40% {transform: translateY(-30px);}
        60% {transform: translateY(-15px);}
        40%,60%{
                
                box-shadow: 0 0 10px 6px rgb(78, 78, 78),
                0 0 5px 3px rgb(255, 255, 255),
                0 0 4px 2px rgb(255, 255, 255),
                0 0 3px 0px rgb(255, 255, 255), 
                0 0 2px rgb(255, 255, 255);
        border-radius: 10px;
        
      border: 1px solid #0026ff;
        text-shadow:  0px 0px 5px #ffffff,
        0px 0px 10px #0059ff,
        0px 0px 20px #0026ff,
        0px 0px 40px #0026ff,
        0px 0px 80px #0026ff,
        0px 0px 120px #0026ff,
        0px 0px 200px #0026ff;
        }
        80%{ box-shadow: none;
                border-radius: 0;
                border: none;
                  text-shadow: none;
        }
        
      }
         
        @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {transform: translateY(0);
        }
        40% {transform: translateY(-30px);}
        60% {transform: translateY(-15px);}
        40%,60%{
                
                box-shadow: 0 0 10px 6px rgb(78, 78, 78),
                0 0 5px 3px rgb(255, 255, 255),
                0 0 4px 2px rgb(255, 255, 255),
                0 0 3px 0px rgb(255, 255, 255), 
                0 0 2px rgb(255, 255, 255);
        border-radius: 10px;
        
      border: 1px solid #0026ff;
        text-shadow:  0px 0px 5px #ffffff,
        0px 0px 10px #0059ff,
        0px 0px 20px #0026ff,
        0px 0px 40px #0026ff,
        0px 0px 80px #0026ff,
        0px 0px 120px #0026ff,
        0px 0px 200px #0026ff;
        }
        80%{ box-shadow: none;
                border-radius: 0;
                border: none;
                  text-shadow: none;
        }
        
      }   
      @-webkit-keyframes wobble {
        0% {transform: translateX(0%);}
        15% {transform: translateX(-25%) rotate(-5deg);}
        30% {transform: translateX(20%) rotate(3deg);}
        45% {transform: translateX(-15%) rotate(-3deg);}
        60% {transform: translateX(10%) rotate(2deg);}
        75% {transform: translateX(-5%) rotate(-1deg);}
        100% {transform: translateX(0%);}
        0%,70%{          
                box-shadow: 0 0 10px 6px rgb(78, 78, 78),
                0 0 5px 3px rgb(255, 255, 255),
                0 0 4px 2px rgb(255, 255, 255),
                0 0 3px 0px rgb(255, 255, 255), 
                0 0 2px rgb(255, 255, 255);
        border-radius: 10px;
      ;
      border: 1px solid darkgreen;
      text-shadow: 0px 0px 10px darkgreen,
      0px 0px 20px darkgreen,
      0px 0px 40px darkgreen,
      0px 0px 80px rgb(0, 138, 0),
      0px 0px 120px rgb(0, 177, 0),
      0px 0px 200px rgb(0, 207, 0);
        } 80%{ box-shadow: none;
                border-radius: 0;
                border: none;
                  text-shadow: none;
        }
      }   
      @keyframes wobble {
        0% {transform: translateX(0%);}
        15% {transform: translateX(-25%) rotate(-5deg);}
        30% {transform: translateX(20%) rotate(3deg);}
        45% {transform: translateX(-15%) rotate(-3deg);}
        60% {transform: translateX(10%) rotate(2deg);}
        75% {transform: translateX(-5%) rotate(-1deg);}
        100% {transform: translateX(0%);}
        0%,70%{          
                box-shadow: 0 0 10px 6px rgb(78, 78, 78),
                0 0 5px 3px rgb(255, 255, 255),
                0 0 4px 2px rgb(255, 255, 255),
                0 0 3px 0px rgb(255, 255, 255), 
                0 0 2px rgb(255, 255, 255);
        border-radius: 10px;
      ;
      border: 1px solid darkgreen;
      text-shadow: 0px 0px 10px darkgreen,
      0px 0px 20px darkgreen,
      0px 0px 40px darkgreen,
      0px 0px 80px rgb(0, 138, 0),
      0px 0px 120px rgb(0, 177, 0),
      0px 0px 200px rgb(0, 207, 0);
        } 80%{ box-shadow: none;
                border-radius: 0;
                border: none;
                  text-shadow: none;
        }
      }




    .navbar{
            width: 100% ;
}

.container-fluid{
            width: 100%;
}

.borderbtn{
            width: 100% ;
}



.navdiv{
        height: 88px;
        position: absolute;
        top: -13px;
        /* left: -15px; */
        z-index: 1;
        width: 100% ;
        border-bottom: 1px solid rgb(0, 0, 0);

}

#heroween {
        word-break: break-all;
        z-index: 9999999;
        left: 0px;
        position: absolute !important;
      padding-top: 0;
      width: 100%;
      min-height: 900px;
      }

      
      #heroween .logo-box {
      position: -webkit-sticky;
      position: sticky;
      top: 15px;
      margin-top: -40px;
      left: 10px !important;
      }
    
      @media(max-width: 919px) {
        #heroween {
        min-height: 690px;}
      }
      @media(max-width: 747px) {
        #heroween {
        min-height: 480px;}
      }
      @media(max-width: 515px) {
        #heroween {
        min-height: 260px;}
      }

      .navbar-brand-v2{
              font-size: 25px;
        position: absolute !important;
        font-weight: bolder !important;
        cursor: pointer;
        text-shadow: 0 2px 3px rgb(0, 0, 0),
        0 2px 4px rgb(0, 0, 0),
        0 2px 4px rgb(255, 255, 255),
        0 2px 5px rgb(255, 255, 255);


}
      #lgif{
              overflow: hidden;
              background-image: linear-gradient(to left, rgba(255, 0, 0, 0.377), rgba(255, 255, 0, 0.404));
              -webkit-animation: animate4 10s linear infinite;
                      animation: animate4 10s linear infinite;
              
      }
      #lgif img{
        position: absolute;
        top: 0;
        opacity: 0;
        height: 35px;
        -webkit-animation-duration: 15s;
                animation-duration: 15s;
        -webkit-animation-name: animetionicon;
                animation-name: animetionicon;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
}

#lgif img:nth-child(1){
        right: 10px;
        top: -10px ;
}
#lgif img:nth-child(2){
        right: 6px;
        top: -13px ;
        -webkit-animation-delay: 5s;
                animation-delay: 5s;
}
#lgif img:nth-child(3){
        right: 13px;
        top: -8px ;
        -webkit-animation-delay: 10s;
                animation-delay: 10s;
}
       
      @-webkit-keyframes animetionicon {
        0%   { 
        opacity: 1;
        }
        33%  { 
                opacity: 1;}
                34%  {opacity: 0;}
        67%  { opacity: 0;}
        100% { opacity: 0;}
      }
       
      @keyframes animetionicon {
        0%   { 
        opacity: 1;
        }
        33%  { 
                opacity: 1;}
                34%  {opacity: 0;}
        67%  { opacity: 0;}
        100% { opacity: 0;}
      }

      .fromerror{
              top: -12px !important;
              color: red;
              font-size: 14px;
              position: relative;
      }
  
      
      @media(min-width: 991px) {

      .lunch-popup .container{
padding: 0 100px !important;


      }}
      .modalv2 {
              top: 0px !important;
        
      }
      .back-to-top{
              position: fixed;
              right: 20px;
              bottom: 60px;
              border-radius: 50%;
              border: none;
              font-size: 30px;
              width: 45px;
              height: 45px;
              max-height: 45px;
              min-height: 45px;
              max-width: 45px;
              min-width: 45px;
              background: linear-gradient(45deg, #005cbe1a,#42a4ff1a);
              color: rgb(124, 124, 124);
              font-weight: bolder;
              z-index: 99;
              text-shadow: 0 1px 1.5px rgb(0, 0, 0),
              0 1px 2px rgb(0, 0, 0),
              0 1px 2px rgb(255, 255, 255),
              0 1px 2.5px rgb(255, 255, 255);
              box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.5),
  2.5px 3.25px 1.5px rgba(0, 0, 0, 0.5),
  2.5px 3px 1.5px rgba(0, 0, 0, 0.5),
  inset -2px -2px 3px rgba(255, 255, 255, 0.9);
      }
      .back-to-top:hover{text-shadow:  0px 0px 5px #000000,
        0px 0px 10px #000000,
        0px 0px 20px #000000,
        0px 0px 40px #000000,
        0px 0px 80px #000000,
        0px 0px 120px #000000,
        0px 0px 200px #000000;
color: #ececec;
font-weight: 100;
}
.video2 {
        max-height: 700px !important;
        height: 100% !important;
        width: 100% !important;
        -o-object-fit: cover !important;
           object-fit: cover !important;
    }
    @media(min-width: 991px) {

        .lunch-popup .container{
  padding: 0 100px;
  
        }}

        .University-slider2{
                margin-bottom: 50px;
        }





        /* ******Thank You*******Thank You*******Thank You*******Thank You*******Thank You*******Thank You*******Thank You*******Thank You*******/

        .thankyou-container{
                margin-top: 150px;
                padding: 0;
                min-height: 50vh;
               
        }
        .thankyou{ 
                padding: 10px;
                border-radius: 10px;
                box-shadow: 0 2px 7px 2px rgba(58, 58, 58, 0.377) !important;
                background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
                margin: 10px 0;color:  #415f81;
        }

        .ma-s{
                margin:0 20px;
        }


        .thankyou-disclaimer{border-radius: 10px;
                box-shadow: 0 2px 7px 2px rgba(58, 58, 58, 0.377) !important;
                
                color: #2d6b6d;
                width: 98%;
                margin-top: 20px ;margin-left: 1%;
                padding: 10px;
                 background: linear-gradient(90deg, rgba(210, 210, 236, 0.8), rgba(212, 240, 243, 0.8)), url("#") no-repeat;
        }
        .thankyou-social-link{border-radius: 10px; display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin: 10px 0;
                padding: 10px 30%;
               
        }
        .thankyou-social-link a i{
                font-size: 50px;
                color: rgb(0, 0, 0);
                margin: 0 10px;
        }

        /* Courses*******Courses******Courses******Courses******Courses******Courses******Courses******Courses******Courses*Courses******Courses******Courses******Courses******Courses******Courses******/

.course-btn{
        background-color: #000;
        text-align: center;
}

.courses-container{
        margin-top: 130px;
}





        .courses{
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 2px 7px 2px rgba(58, 58, 58, 0.377) !important;
                background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
                margin: 20px 20px;
        }
        .courseshead {color:  #415f81;
                text-align: center;
                margin: 30px;
        }
        .courses .row .thr1 h2{
                font-size: 24px;
                color: #2d6b6d;
        }
        .courses .row .thr2 h3{
                font-size: 18px;
                color:  #3b1313;
                min-width: 200px;
                font-weight: bolder;
        }
        .courses p{
                font-size: 16px;
                color: #293866;
                font-weight: lighter;
        }

        /* Rules************Rules************Rules************Rules************Rules************Rules************Rules************Rules************Rules************Rules************/
      
        /* .Rules-container{

             background-color: #043235;
        } */
        /* .Rules{
                background-color: #00ff0048;
        } */
        .Rules h1{color:  #415f81;
                margin: 20px;
                padding: 60px;
        }
        .rulecontain {
                padding: 10px;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 2px 7px 2px rgba(58, 58, 58, 0.377) !important;
                background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
                margin: 20px 0px;
        }
        .rulecontain p{
                text-align: justify;
                color: #293866;
        }
        .rulecontain ul li{
                list-style: number;
                color: #2d6b6d;
        }
        .li-none{
                list-style: none !important;
        }
        .rulecontain h3{
                color: #0d331b !important;
                margin-bottom: 15px;
                font-size: 25px;
        }
        .Rules h6{
                margin-left: 50px;
                color:  #3b1313;
                font-size: 20px;
        }
        /* NCHMExamRules************NCHMExamRules************NCHMExamRules************NCHMExamRules************NCHMExamRules************NCHMExamRules************NCHMExamRules************NCHMExamRules************NCHMExamRules************NCHMExamRules************/
        
        
        .NCHMExamRules-container{
                padding: 0;
                margin-top: 130px;
        }
        .NCHMExamRules-container .row .col-md-12 h1{
                color:  #415f81;
                line-height: 60px;
        }
        .lunch-box{ padding: 20px;
                border-radius: 10px;
                /* box-shadow: 0 2px 7px 2px rgba(58, 58, 58, 0.377) !important;
                background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232)); */

                margin: 10px 50px;
        }
        
        .NCHMExamRules-container .row .col-md-12 img{
                background-color: #00ff005b;
                display: none;
        }
        .NCHMExamRules-container .row .col-md-12 h2{
                margin: 20px;
                font-size: 25px;
                color: #0d331b !important;
        }
        .NCHMExamRules-container .row .col-md-12 p{
                color:  #3b1313;
        }
        .NCHMExamRules-contain2 h1{
                color: #383855 !important;
                font-size: 30px;
        }
        .NCHMExamRules-contain2 p{color:  #3b1313;
                font-size: 14px !important;
        }
        .NCHMExamRules h2{
                margin-top: 0 !important;
        }
        .NCHMExamRules-contain2{background-color: #e7f0ea;
                margin: 20px;padding: 20px;
        }
        @media(max-width: 575px) {
                .NCHMExamRules{margin: 20px 0px !important;
                }
                .lunch-box{ 
                        margin: 10px 35px;}
                        .NCHMExamRules-contain2{
                                margin: 20px 0;}
        }
        .NCHMExamRules{
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 2px 7px 2px rgba(58, 58, 58, 0.377) !important;
                background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
                margin: 20px 20px;
        }
        .NCHMExamRules h4 {
                color: #034346;
                margin-bottom: 20px;
        }
        .NCHMExamRules p {
                color: #293866 !important;
                font-size: 18px;
        }
        .NCHMExamRules .p2{
                font-size: 20px;
                font-weight: bolder;
                color: #1e4234 !important;
        }
        .list-alpha{margin-top: 20px;
                list-style:upper-alpha;
                font-size: 15px;
                color: #330838;
        }
        .list-alpha li{
              margin-top: 15px;
        }
        .list-italic{margin-top: 20px;
                list-style:lower-roman;
                font-size: 15px;
                color: #2b1057;
        }
        .list-italic li{
              margin-top: 15px;
        }
        .sub-child{
                margin-left: 30px;
        }
        
        .NCHMExamRules table{
                background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
                margin: 20px;
        }

        .NCHMExamRules table, th, td {                
             border: 1px solid #043235; 
             padding: 10px; 
             color: #043235; 
        }
        .NCHMExamRules  th {    
                background-color: #043235;            
                text-align: center;
                color: white;
                font-weight: lighter;
           }
           .NCHMExamRules  td:nth-child(2) {                
                text-align: center;
           }
           .popuopz{
                z-index: 9999999 !important;
        }

        .nchmctjee-container {
                top: 0;
                margin-top: 130px;
                padding: 15vh !important;
        }
        .nchmctjee {
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 2px 7px 2px rgba(58, 58, 58, 0.377) !important;
                background: linear-gradient(45deg,  rgb(230, 230, 243),rgb(232, 243, 232));
                margin: 20px 20px;
        }
        .nchmctJee h3{
                margin-top: 2vh;
               
                margin-bottom: 2vh;
        }
        .nchmctjee-study-container {
                top: 0;
                /* margin-top: 130px; */
                padding: 15vh !important;
        }
        .syllabus-container {
                top: 0;
                /* margin-top: 130px; */
                padding: 15vh !important;
        }
        .syllabus-container  h3,p{
               padding-left: 5px;
        }
